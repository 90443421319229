
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      hasLeadsTotalChanged: false,
    }
  },

  computed: {
    ...mapState('navigation', ['pages', 'isOpen']),
    ...mapState('leads', ['customersMeta']),

    leadsTotal() {
      return this.customersMeta.total
    },
  },

  watch: {
    // Leads gets fetched on interval in <ActionButtons />
    leadsTotal(newValue, oldValue) {
      // If oldValue is undefined, it means that it is initial
      if (oldValue) {
        this.hasLeadsTotalChanged = true
      }
    },

    $route(newValue) {
      // Reset the leads total change flag when navigating to neue-leads
      if (newValue.path === '/neue-leads') {
        this.hasLeadsTotalChanged = false
      }
    },
  },

  mounted() {
    // Load the leads on mount to have the total count
    try {
      this.$store.dispatch('leads/loadAll')
    } catch (e) {
      console.error({ e })
    }
  },

  methods: {
    ...mapMutations('navigation', ['closeNav']),
  },
}
