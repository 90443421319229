import { render, staticRenderFns } from "./LogoLightBtn.vue?vue&type=template&id=08cab950"
import script from "./LogoLightBtn.vue?vue&type=script&lang=js"
export * from "./LogoLightBtn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoLight: require('/vercel/path0/components/icons/LogoLight.vue').default})
