export const state = () => ({
  objectInsurances: null,
})

export const actions = {
  async create({ commit }, { objectId, data }) {
    data = formatDates(this.$dateFns, data)
    data = replaceUndefinedWithNull(data)

    const objectInsurance = await this.$axios.$post(
      `objects/${objectId}/object-insurances`,
      data
    )
    return objectInsurance.data
  },

  async update({ dispatch }, { id, data }) {
    data = formatDates(this.$dateFns, data)
    data = replaceUndefinedWithNull(data)

    const objectInsurance = await this.$axios.$patch(
      `/object-insurances/${id}`,
      data
    )

    return objectInsurance.data
  },

  async destroy({ commit }, { id }) {
    await this.$axios.$delete(`/object-insurances/${id}`)
  },

  async destroyDocument({ commit }, { objectInsuranceId, documentId }) {
    await this.$axios.$delete(
      `object-insurances/${objectInsuranceId}/documents/${documentId}`
    )
  },

  async destroyAllDocuments({ commit }, { objectInsuranceId }) {
    await this.$axios.$delete(
      `object-insurances/${objectInsuranceId}/documents/all`,
      {
        params: {
          mediaGroup: 'object-insurance-documents',
        },
      }
    )
  },
}

export const getters = {}

function formatDates(dateFns, data) {
  const dateFields = [
    'due_on',
    'valid_from',
    'one_time_commission_paid_at',
    'one_time_commission_expected_at',
    'annual_commission_expected_at',
    'annual_commission_paid_at',
    'annual_commission_active_at',
    'canceled_at',
    'repayment_repaid_at',
  ]

  dateFields.forEach((field) => {
    const value = data[field]

    if (value === true) {
      // If true, use today's date
      data[field] = dateFns.format(new Date(), 'yyyy-MM-dd')
    } else if (value === false || value === null) {
      // If false, set it to null
      data[field] = null
    } else if (value instanceof Date || !isNaN(new Date(value))) {
      // If it's a valid date or parsable, format it
      data[field] = dateFns.format(value, 'yyyy-MM-dd')
    } else {
      // If invalid, set to null
      data[field] = null
    }
  })

  return data
}

function replaceUndefinedWithNull(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] === undefined) {
      data[key] = null
    }
  })

  return data
}
