import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0318eb20"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SiteNavigation: require('/vercel/path0/components/navigation/SiteNavigation.vue').default,Alerts: require('/vercel/path0/components/ui/Alerts.vue').default})
